import { computed, reactive, ref, watch } from "vue";

type UndefStr = undefined | string;

export const useSearch = () => {
  const usedCarVin = reactive({
    label: "二手车车架号",
    vins: [],
    batchQueryTitle: "VinNum Batch Query",
    batchQueryListTitle: "VinNum List",
  });

  const changeUsedCarVin = (vals: any) => {
    usedCarVin.vins = vals;
  };

  const newCarVin = reactive({
    label: "新车车架号",
    vins: [],
    batchQueryTitle: "VinNum Batch Query",
    batchQueryListTitle: "VinNum List",
  });

  const changeNewCarVin = (vals: any) => {
    newCarVin.vins = vals;
  };

  const carNo = ref<UndefStr>(undefined);

  // 二手车开票
  const invoiceDate = reactive({
    label: "二手车开票日期",
    startDate: "",
    endDate: "",
  });
  // 新车开票
  const newInvoiceDate = reactive({
    label: "新车开票日期",
    startDate: "",
    endDate: "",
  });
  // 二手车过户
  const transformDate = reactive({
    label: "二手车过户日期",
    startDate: "",
    endDate: "",
  });
  // 提交
  const submitDate = reactive({
    label: "提交日期",
    startDate: "",
    endDate: "",
  });

  const reset = () => {
    usedCarVin.vins = [];
    newCarVin.vins = [];
    carNo.value = undefined;
    invoiceDate.startDate = "";
    invoiceDate.endDate = "";
    newInvoiceDate.startDate = "";
    newInvoiceDate.endDate = "";
    transformDate.startDate = "";
    transformDate.endDate = "";
    submitDate.startDate = "";
    submitDate.endDate = "";
  };

  return {
    usedCarVin,
    changeUsedCarVin,
    newCarVin,
    changeNewCarVin,
    carNo,
    invoiceDate,
    newInvoiceDate,
    transformDate,
    submitDate,
    reset,
  };
};
