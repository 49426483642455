import { reactive } from "vue";
export const useTable = () => {
  const cpoColumns = reactive<any>([
    {
      title: "卖主名称",
      dataIndex: "seller",
      align: "center",
      width: 260,
    },
    {
      title: "卖主身份证号/组织机构代码",
      dataIndex: "sellerCode",
      align: "center",
      width: 220,
    },
    {
      title: "认证日期",
      dataIndex: "certificationDate",
      align: "center",
      width: 100,
    },
    {
      title: "车架号",
      dataIndex: "vinNo",
      align: "center",
      width: 190,
    },
    { title: "系列", dataIndex: "series", align: "center", width: 300 },
    {
      title: "型号",
      dataIndex: "typeClass",
      align: "center",
      width: 100,
    },
    {
      title: "首次登记日期",
      dataIndex: "firstRegistDate",
      align: "center",
      width: 150,
    },

    {
      title: "新车保修起始日期",
      dataIndex: "startRepairDate",
      align: "center",
      width: 180,
    },

    {
      title: "买主名称",
      dataIndex: "buyerName",
      align: "center",
      width: 300,
    },
    {
      title: "保修编号",
      dataIndex: "repairCode",
      align: "center",
      width: 150,
    },
    {
      title: "认证证书编号",
      dataIndex: "certificateCode",
      align: "center",
      width: 150,
    },
    {
      title: "转移类型(过户/迁出)",
      dataIndex: "transferType",
      align: "center",
      width: 180,
      slots: { customRender: "transferType" },
    },
    {
      title: "二手车开票日期",
      dataIndex: "invoiceDate",
      align: "center",
      width: 180,
    },
    {
      title: "二手车过户日期(或临牌签发日期)",
      dataIndex: "transferDate",
      align: "center",
      width: 250,
    },
    {
      title: "提交日期",
      dataIndex: "submitDate",
      align: "center",
      width: 90,
    },
    {
      title: "审核状态",
      dataIndex: "checkStatus",
      align: "center",
      width: 130,
      slots: { customRender: "checkStatus" },
    },
    {
      title: "审核结果",
      dataIndex: "checkResult",
      align: "center",
      width: 90,
      slots: { customRender: "checkResult" },
    },
    {
      title: "反馈信息",
      dataIndex: "feedbackMsg",
      align: "center",
      width: 210,
      slots: { customRender: "feedbackMsg" },
    },
    {
      title: "待提交",
      fixed: "right",
      width: 100,
      dataIndex: "notSubmit",
      slots: { customRender: "notSubmit" },
    },
    {
      title: "文件",
      dataIndex: "file",
      fixed: "right",
      slots: { customRender: "document" },
      align: "center",
      width: 600,
    },
    {
      title: "操作",
      dataIndex: "action",
      fixed: "right",
      slots: { customRender: "action" },
      align: "center",
      width: 200,
    },
  ]);
  const tradeInColumns = reactive<any>([
    {
      title: "品牌／系列",
      dataIndex: "series",
      align: "center",
      width: 200,
    },
    {
      title: "车型",
      dataIndex: "typeClass",
      align: "center",
      width: 270,
    },
    {
      title: "二手车VIN码",
      dataIndex: "usedCarVinNo",
      align: "center",
      width: 220,
    },
    {
      title: "车牌号码 (过户前)",
      dataIndex: "vehicleLicense",
      align: "center",
      width: 170,
    },
    {
      title: "首次登记日期",
      dataIndex: "firstRegistDate",
      align: "center",
      width: 150,
    },
    {
      title: "二手车过户日期",
      dataIndex: "transferDate",
      align: "center",
      width: 150,
    },
    {
      title: "车辆VIN码",
      dataIndex: "vehicleVinNo",
      align: "center",
      width: 220,
    },
    {
      title: "新车开票日期",
      dataIndex: "newInvoiceDate",
      align: "center",
      width: 110,
    },
    {
      title: "二手车卖主名称",
      dataIndex: "seller",
      align: "center",
      width: 120,
    },
    {
      title: "卖主身份证号/组织机构代码",
      dataIndex: "sellerCode",
      align: "center",
      width: 200,
    },
    {
      title: "和新车买主的关系",
      dataIndex: "relation",
      align: "center",
      width: 130,
    },
    {
      title: "新车买主名称",
      dataIndex: "buyerName",
      align: "center",
      width: 110,
    },
    {
      title: "买主身份证号/组织机构代码",
      dataIndex: "buyerCode",
      align: "center",
      width: 200,
    },
    {
      title: "提交日期",
      dataIndex: "submitDate",
      align: "center",
      width: 90,
    },
    {
      title: "审核状态",
      dataIndex: "checkStatus",
      align: "center",
      width: 130,
      slots: { customRender: "checkStatus" },
    },
    {
      title: "审核结果",
      dataIndex: "checkResult",
      align: "center",
      width: 90,
      slots: { customRender: "checkResult" },
    },
    {
      title: "反馈信息",
      dataIndex: "feedbackMsg",
      align: "center",
      width: 210,
      slots: { customRender: "feedbackMsg" },
    },
    {
      title: "待提交",
      fixed: "right",
      width: 100,
      dataIndex: "notSubmit",
      slots: { customRender: "notSubmit" },
    },
    {
      title: "文件",
      dataIndex: "file",
      fixed: "right",
      slots: { customRender: "document" },
      align: "center",
      width: 600,
    },
    {
      title: "操作",
      dataIndex: "action",
      fixed: "right",
      slots: { customRender: "action" },
      align: "center",
      width: 200,
    },
  ]);

  return {
    tradeInColumns,
    cpoColumns,
  };
};
