
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import axios from 'axios';
import { defineComponent, reactive, ref, onBeforeUnmount } from 'vue';
import { FileItem } from '@/API/types';
import moment from 'moment';
import downloadFile from '@/utils/rv/downloadFile';
import { fileUpload, fileUploadTrande, cancelUpload, cancelUploadTrande, getOcrist } from '@/API/userCar';
import { guid } from '@/utils/index';
import { message, Modal } from 'ant-design-vue';
const columns = [
  {
    title: '车架号',
    dataIndex: 'vinNo',
    key: 'vinNo',
    width: 100
  },
  {
    title: '文件名',
    dataIndex: 'fileName',
    key: 'fileName',
    width: 150
  },
  {
    title: '提交结果',
    dataIndex: 'ocrResult',
    key: 'ocrResult',
    width: 150,
    slots: { customRender: 'ocrResult' }
  }
];

export default defineComponent({
  emits: ['close-batch-visible', 'get-modal-info', 'init-table'],
  props: {
    isBatchVisible: {
      type: Boolean,
      require: true,
      default: false
    },
    single: {
      type: Boolean,
      require: true,
      default: false
    },
    istab: {
      type: Number,
      require: true,
      default: 0
    },
    recordVin: {
      type: Object,
      require: true,
      default: () => {
        return { vinNo: '车架号', id: undefined };
      }
    }
  },

  setup(props, { emit }) {
    const showVinNo = ref('');
    const nameRules = ref('无要求');
    if ((props as any).istab == 0) {
      showVinNo.value = (props as any).recordVin.vinNo;
    } else {
      showVinNo.value = (props as any).recordVin.vehicleVinNo;
    }
    const infoVisible = ref(false);
    const isUploading = ref(false);
    const isCheckingOcr = ref(false);
    const isvisible = (props as any).isBatchVisible;
    const isComplete = ref('完成');

    const activeKey = ref('1');
    const changeActiveKey = (index: string) => {
      activeKey.value = index;
    };

    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 10
    });
    //弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: '',
      content: '',
      btnVisible: false
    });
    // 校验文件名称的正则
    let regex = /^SFZM_/g;
    // 切换类型
    const isSelected = ref<number>(1);
    const fileNameShow = ref<string>('二手车发票');

    //文件上传数量
    const licenseUploadComplete = ref<boolean>(true);
    const licenseCount = ref(0);
    const licenseFile = ref([]);
    const licenseContent = ref<string>('0/0');
    const licenseFileSize = ref<string>('');
    const licenseKey = ref<string>(guid());
    let licenseFormData = new FormData();
    //行驶证进度条
    const tempLicenseData = ref([]);
    const licenseTit = ref<string>('文件上传');
    const licensePercent = ref<number>(0);
    const licenseFileNum = ref<number>(0);
    const isLicenseSuccess = ref<boolean>(false);
    const licenseProStatus = ref<string>('normal');
    const uploadError = ref<boolean>(false);
    const licenseClickCount = ref<number>(0);
    // const licenseSubSuccess = ref<boolean>(false)

    //OCR进度条
    const orcTit = ref<string>('OCR识别');
    const ocrPercent = ref<number>(0);
    const ocrProStatus = ref<string>('normal');
    const ocrContent = ref<string>('');
    const ocrFileSize = ref<string>('');
    const ocrKey = ref<string>(guid());
    const dataContent: any = reactive([]);
    const dataSource = ref({
      content: []
    });
    const isContinue = ref<boolean>(false);
    const size = ref<number>(10);
    const closeCount = ref<number>(0);
    const timer = ref<number>(0);
    let isCancelUpload = false;
    //继续上传,清空数据
    const continueSumbit = () => {
      sessionStorage.setItem('vlLicenseData', '');
      sessionStorage.setItem('isOcrContinue', '');
      licenseUploadComplete.value = true;
      isContinue.value = false;
      licenseKey.value = guid();
      licensePercent.value = 0;
      ocrPercent.value = 0;
      licenseFormData.delete('files');
    };
    // 取消上传事件 cpo+置换
    const cancelUp = () => {
      if ((props as any).isTab == 0) {
        cancelUpload({
          batchId: sessionStorage.getItem('batchNo') as any
        }).then(() => {
          // continueSumbit(); //取消成功重置上传状态
          // message.success("上传取消成功！");
          emit('close-batch-visible', { isBatchVisible: false });
        });
      } else {
        cancelUploadTrande({
          batchId: sessionStorage.getItem('batchNo') as any
        }).then(() => {
          // message.success("上传取消成功！");
          emit('close-batch-visible', { isBatchVisible: false });
        });
      }
    };
    const pT = ref<any>(0);
    // 关闭弹窗取消上传
    const close = () => {
      clearInterval(timer.value);
      clearInterval(pT.value);
      if (isCancelUpload) {
        setTimeout(() => {
          isCancelUpload = false;
        });
        return;
      }

      closeCount.value = 1;
      if (isUploading.value) {
        cancelUp();
        modalParams.noticeType = 'warning';
        modalParams.content = '正在批量上传，此操作会取消该批次文件的全部上传，确定要关闭窗口？';
        modalParams.btnVisible = true;
        Object.assign(modalData, modalParams);
        sessionStorage.setItem('batchNo', licenseKey.value);
        emit('get-modal-info', {
          modalVisible: true,
          modalData: modalData
        });
      } else if (isCheckingOcr.value) {
        cancelUp();
        modalParams.noticeType = 'warning';
        modalParams.content = 'OCR识别中，关闭窗口后可在任务中心关注后续进程及结果';
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
        sessionStorage.setItem('batchNo', licenseKey.value);
        emit('get-modal-info', {
          modalVisible: true,
          modalData: modalData
        });
      } else {
        delete sessionStorage['batchNo'];
        emit('close-batch-visible', { isBatchVisible: false });
      }
      emit('init-table', {}); //上传成功刷新主表格
    };
    const errorMessage = ref<string>('');
    const hasError = ref(false); //是否有错误标记，用于在change时，拦截，清空file相关信息
    // 重写默认上传方法 获取合规的行驶证图片信息
    const licenseCustomRequest = () => {
      console.log(2);
      if (!hasError.value) {
        const size = ref<number>(0);
        licenseFile.value.forEach((ele: any) => {
          size.value = size.value + ele.size;
        });
        licenseFileNum.value = licenseFile.value.length;
        licenseContent.value = '0/' + licenseFileNum.value + '（0%完成）';
        licenseFileSize.value = (size.value / 1024 / 1024).toFixed(2) + 'MB';
        licenseUploadComplete.value = false;
      }
    };
    let nowSelectedFileNum = 0;
    //预检查
    const lastSign = ref(false); //最后一个文件标记，用于批量上传，告警
    let submitSign = true;
    const beforeUpload = (file: FileItem, fileList: any) => {
      console.log(' beforeUploadfile', file, fileList);
      const nameT = file.name + '';
      nowSelectedFileNum = fileList.length;
      if (fileList.length > 1 && file === fileList[fileList.length - 1]) {
        lastSign.value = true;
        // if (errorMessage.value.length>0){
        //   message.error(errorMessage.value)
        //   errorMessage.value = "";

        //   hasError.value = true;
        //   licenseCount.value = 1;
        //   return false;
        // }
      } else {
        lastSign.value = false;
        // return true;
      }
      if (fileList.length === 1) {
        lastSign.value = true;
      }

      return new Promise((resolve, reject) => {
        // console.log(file)
        // 校验上传文件命名规则
        if (isSelected.value == 3) {
          regex = /^SFZM_/g;

          if (!regex.test(nameT)) {
            errorMessage.value = '文件命名有误，请上传以SFZM__VIN命名的身份证明文件';
            if (lastSign.value) {
              message.error('文件命名有误，请上传以SFZM__VIN命名的身份证明文件');
              lastSign.value = false;
              hasError.value = true;
              errorMessage.value = '';
            }

            return false;
          } else {
            const splitName = nameT.split('_');
            const isVin = splitName[1].split('.');
            if (isVin[0] == '') {
              if (lastSign.value) {
                message.error('文件命名有误，请上传以SFZM__VIN命名的身份证明文件');
                lastSign.value = false;
                hasError.value = true;
                errorMessage.value = '';
              } else {
                errorMessage.value = '文件命名有误，请上传以SFZM__VIN命名的身份证明文件';
              }
              return false;
            }
          }
        } else if (isSelected.value == 4) {
          regex = /^JSHT_/g;

          if (!regex.test(nameT)) {
            errorMessage.value = '文件命名有误，请上传以JSHT_VIN命名的寄售合同文件';

            if (lastSign.value) {
              message.error('文件命名有误，请上传以JSHT_VIN命名的寄售合同文件');
              lastSign.value = false;
              hasError.value = true;
              licenseCount.value = 1;
              errorMessage.value = '';
            }
            return false;
          } else {
            const splitName = nameT.split('_');
            const isVin = splitName[1].split('.');
            if (isVin[0] == '') {
              if (lastSign.value) {
                message.error('文件命名有误，请上传以JSHT_VIN命名的寄售合同文件');
                lastSign.value = false;
                hasError.value = true;
                errorMessage.value = '';
              } else {
                errorMessage.value = '文件命名有误，请上传以JSHT_VIN命名的寄售合同文件';
              }
              return false;
            }
          }
        } else if (isSelected.value == 5) {
          regex = /^XSHT_/g;

          if (!regex.test(nameT)) {
            if (lastSign.value) {
              message.error('文件命名有误，请上传以XSHT_VIN命名的销售合同文件');
              lastSign.value = false;
              hasError.value = true;
              licenseCount.value = 1;
              errorMessage.value = '';
            }
            errorMessage.value = '文件命名有误，请上传以XSHT_VIN命名的销售合同文件';
            return false;
          } else {
            const splitName = nameT.split('_');
            const isVin = splitName[1].split('.');
            if (isVin[0] == '') {
              if (lastSign.value) {
                message.error('文件命名有误，请上传以XSHT_VIN命名的销售合同文件');
                lastSign.value = false;
                hasError.value = true;
                errorMessage.value = '';
              } else {
                errorMessage.value = '文件命名有误，请上传以XSHT_VIN命名的销售合同文件';
              }
              return false;
            }
          }
        } else if (isSelected.value == 6) {
          regex = /^RZBG_/g;

          if (!regex.test(nameT)) {
            if (lastSign.value) {
              message.error('文件命名有误，请上传以RZBG_VIN命名的认证报告文件');
              lastSign.value = false;
              hasError.value = true;
              licenseCount.value = 1;
              errorMessage.value = '';
            }
            errorMessage.value = '文件命名有误，请上传以RZBG_VIN命名的认证报告文件';
            return false;
          } else {
            const splitName = nameT.split('_');
            const isVin = splitName[1].split('.');
            if (isVin[0] == '') {
              if (lastSign.value) {
                message.error('文件命名有误，请上传以RZBG_VIN命名的认证报告文件');
                lastSign.value = false;
                hasError.value = true;
                errorMessage.value = '';
              } else {
                errorMessage.value = '文件命名有误，请上传以RZBG_VIN命名的认证报告文件';
              }
              return false;
            }
          }
        } else if (isSelected.value == 7) {
          regex = /^BXBG_/g;

          if (!regex.test(nameT)) {
            if (lastSign.value) {
              message.error('文件命名有误，请上传以BXBG_VIN命名的保修报告文件');
              lastSign.value = false;
              hasError.value = true;
              licenseCount.value = 1;
              errorMessage.value = '';
            }
            errorMessage.value = '文件命名有误，请上传以BXBG_VIN命名的保修报告文件';
            return false;
          } else {
            const splitName = nameT.split('_');
            const isVin = splitName[1].split('.');
            if (isVin[0] == '') {
              if (lastSign.value) {
                message.error('文件命名有误，请上传以BXBG_VIN命名的保修报告文件');
                lastSign.value = false;
                hasError.value = true;
                errorMessage.value = '';
              } else {
                errorMessage.value = '文件命名有误，请上传以BXBG_VIN命名的保修报告文件';
              }
              return false;
            }
          }
        } else if (isSelected.value == 8) {
          regex = /^QT_/g;

          if (!regex.test(nameT)) {
            if (lastSign.value) {
              message.error('文件命名有误，请上传以QT_VIN命名的其他文件');
              lastSign.value = false;
              hasError.value = true;
              licenseCount.value = 1;
              errorMessage.value = '';
            }
            errorMessage.value = '文件命名有误，请上传以QT_VIN命名的其他文件';
            return false;
          } else {
            const splitName = nameT.split('_');
            const isVin = splitName[1].split('.');
            if (isVin[0] == '') {
              if (lastSign.value) {
                message.error('文件命名有误，请上传以QT_VIN命名的其他文件');
                lastSign.value = false;
                hasError.value = true;
                errorMessage.value = '';
              } else {
                errorMessage.value = '文件命名有误，请上传以QT_VIN命名的其他文件';
              }
              return false;
            }
          }
        }
        const params = reactive({
          noticeType: '',
          content: '',
          btnVisible: false
        });
        //判断文件格式
        const isJpg = file.type === 'image/jpeg';
        const isPdf = file.type === 'application/pdf';
        if (!isJpg && !isPdf) {
          params.noticeType = 'error';
          params.content = '文件格式错误，请按照JPG PDF 等格式进行文件上传';
          errorMessage.value = '文件格式错误，请按照JPG PDF 等格式进行文件上传';
          if (lastSign.value) {
            message.error('文件格式错误，请按照JPG PDF 等格式进行文件上传');
            lastSign.value = false;
            hasError.value = true;
            errorMessage.value = '';
          }
          params.btnVisible = false;
          emit('get-modal-info', {
            modalVisible: true,
            modalData: params
          });
          licenseCount.value = 1;
          // message.error("文件格式错误，请按照JPG PDF 等格式进行文件上传")
          return reject(false);
        }
        //判断文件大小
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = 'error';
          params.content = '文件大小必须小于5MB!';
          errorMessage.value = '文件大小必须小于5MB!';
          if (lastSign.value) {
            message.error('文件大小必须小于5MB');
            lastSign.value = false;
            hasError.value = true;
            errorMessage.value = '';
          }
          params.btnVisible = false;
          emit('get-modal-info', {
            modalVisible: true,
            modalData: params
          });
          licenseCount.value = 1;
          // message.error("文件大小必须小于5MB!")
          return reject(false);
        }
        //判断图片数量
        const fileLength = fileList.length;
        if (fileLength > 100) {
          params.noticeType = 'error';
          params.content = '文件数量不能大于100张';
          errorMessage.value = '文件数量不能大于100张';
          if (lastSign.value) {
            message.error('文件数量不能大于100张');
            lastSign.value = false;
            hasError.value = true;
            errorMessage.value = '';
          }
          params.btnVisible = false;
          emit('get-modal-info', {
            modalVisible: true,
            modalData: params
          });
          licenseCount.value = 1;
          // message.error("文件数量不能大于100张")
          return reject(false);
        }
        if (lastSign.value && errorMessage.value.length > 0) {
          message.error(errorMessage.value);
          lastSign.value = false;
          errorMessage.value = '';
          hasError.value = true;
          return resolve(false);
        }

        if (lastSign.value && errorMessage.value.length === 0) {
          lastSign.value = false;
          errorMessage.value = '';
          hasError.value = false;
          licenseFile.value = fileList;
          tempLicenseData.value = fileList;
          submitSign = true;
          return resolve(true);
        }

        // licenseFile.value = fileList;
        // tempLicenseData.value = fileList;
        // licenseCount.value = 0;
        // return resolve(true);
      });
    };
    //文件上传进度条显示
    const CANCEL_TOKEN = axios.CancelToken;
    let cancelFunction = () => {
      console.log('no uploading');
    };
    const licenseConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          licensePercent.value = complete;
          licenseContent.value =
            Math.floor((progressEvent.loaded / progressEvent.total) * licenseFileNum.value) +
            '/' +
            licenseFileNum.value +
            '（' +
            complete +
            '%完成）';
        }
      },
      // 取消上传
      cancelToken: new CANCEL_TOKEN((c) => {
        //强行中断请求要用到的，记录请求信息
        cancelFunction = () => {
          isCancelUpload = true;
          c();
        };
      })
    };

    //轮询获取ocr返回数据
    const pollingGetData = () => {
      if ([1, 2, '1', '2'].includes(isSelected.value) && dataContent.length < licenseFileNum.value) {
        pT.value = setInterval(async () => {
          getOcrist({
            batchId: sessionStorage.getItem('batchNo'),
            current: pagination.currentPage,
            size: pagination.pageSize
          }).then((res: any) => {
            if (res.totalElements >= licenseFileNum.value) {
              clearInterval(pT.value);
              pT.value = 0;
            }
            dataContent.length = 0;
            dataContent.push(...res.content);
            if ((props as any).single) {
              let vinNo = '';
              if ((props as any).istab == 0) {
                vinNo = (props as any).recordVin.vinNo;
              } else {
                vinNo = (props as any).recordVin.vehicleVinNo;
              }

              if (dataContent.length) {
                dataContent[0].vinNo = vinNo;
              }
            }
            isContinue.value = true; //后续表格展示
            pagination.pageSize = res.size;
            pagination.currentPage = 1;
            pagination.total = res.totalElements;
          });
        }, 3000);
      }
    };

    // 上传成功展示ocr信息
    const showOcrTable = () => {
      getOcrist({
        batchId: sessionStorage.getItem('batchNo'),
        current: pagination.currentPage,
        size: pagination.pageSize
      }).then((res: any) => {
        dataContent.length = 0;
        dataContent.push(...res.content);
        if ((props as any).single && dataContent.length > 0) {
          let vinNo = '';
          if ((props as any).istab == 0) {
            vinNo = (props as any).recordVin.vinNo;
          } else {
            vinNo = (props as any).recordVin.vehicleVinNo;
          }
          dataContent[0].vinNo = vinNo;
        }
        isContinue.value = true; //后续表格展示
        pagination.pageSize = res.size;
        pagination.currentPage = res.totalPages;
        pagination.total = res.totalElements;
        pollingGetData();
      });
    };
    const showOcrTable2 = () => {
      getOcrist({
        batchId: sessionStorage.getItem('batchNo'),
        current: pagination.currentPage,
        size: pagination.pageSize
      }).then((res: any) => {
        dataContent.length = 0;
        dataContent.push(...res.content);
        if ((props as any).single && dataContent.length) {
          let vinNo = '';
          if ((props as any).istab == 0) {
            vinNo = (props as any).recordVin.vinNo;
          } else {
            vinNo = (props as any).recordVin.vehicleVinNo;
          }
          dataContent[0].vinNo = vinNo;
        }
        isContinue.value = true; //后续表格展示
        pagination.pageSize = res.size;
        pagination.currentPage = res.totalPages;
        pagination.total = res.totalElements;
      });
    };
    //批量上传行驶证 cpo+置换
    const batchLicense = async () => {
      isUploading.value = true;
      try {
        if ((props as any).recordVin.id) {
          licenseFormData.append('id', (props as any).recordVin.id);
        } else {
          licenseFormData.append('id', '');
        }
        licenseFormData.append('type', isSelected.value as any);
        licenseFormData.append('batchId', licenseKey.value as any);
        sessionStorage.setItem('batchNo', licenseKey.value);
        const res: any =
          parseInt((props as any).istab) == 0
            ? await fileUpload(licenseFormData, licenseConfig)
            : await fileUploadTrande(licenseFormData, licenseConfig);
        isUploading.value = false;
        //判断返回的code值 除0外都是异常
        if (res.code !== '0') {
          isLicenseSuccess.value = false;
          licenseProStatus.value = 'exception';
          licenseContent.value = '上传失败';
          uploadError.value = true;
          isComplete.value = '上传失败';
          modalParams.content = res.message;
        } else {
          isComplete.value = '完成';
          licenseProStatus.value = 'normal';
          isLicenseSuccess.value = true;
        }
      } catch (e) {
        if (isCancelUpload) {
          setTimeout(() => {
            isCancelUpload = false;
          });
          return;
        }
        isLicenseSuccess.value = false;
        uploadError.value = true;
        licenseProStatus.value = 'exception';
        licenseContent.value = '上传失败';
        modalParams.content = '上传失败';
      }
    };

    // 上传报错 关闭弹窗 重置相应数据
    const isResetModal = (param: { continue: boolean }) => {
      const isCloseModal = param.continue;
      if (isCloseModal) {
        if (licenseProStatus.value == 'exception') {
          licenseUploadComplete.value = true;
          licenseProStatus.value = 'normal';
          licensePercent.value = 0;
          tempLicenseData.value = [];
          licenseFormData.delete('files');
        }
        if (ocrProStatus.value == 'exception') {
          licenseUploadComplete.value = true;
          ocrProStatus.value = 'normal';
          ocrPercent.value = 0;
          tempLicenseData.value = [];
          licenseFormData.delete('files');
        }
      }
    };
    // 图片格式错误 再次上传清空之前记录数
    const isResetCount = (param: { continue: boolean }) => {
      const resetCount = param.continue;
      if (resetCount) {
        licenseCount.value = 0;
      }
    };
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      showOcrTable2();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      showOcrTable2();
    };
    // ocr提交获取进度
    const batchSub = async (type: string, batchNo: string) => {
      const params = {
        batchNo: batchNo
      };
      isContinue.value = false;
      dataSource.value = {
        content: []
      };
      try {
        // const res = await batchFileSubmit({ params });
        // 临时进度条设置
        const tempFun = (res: any) => {
          if (res.code !== '0') {
            ocrProStatus.value = 'exception';
            ocrContent.value = '提交失败';
            clearInterval(timer.value);
            timer.value = 0;
            modalParams.content = res.message;
          } else {
            const useCurrent = res.data.current;
            const useTotal = res.data.total;
            const percentage = ((useCurrent / useTotal) * 100) | 0;
            ocrProStatus.value = 'normal';
            ocrPercent.value = percentage;
            ocrContent.value = useCurrent + '/' + useTotal + '（' + ocrPercent.value + '%完成）';

            if (useCurrent !== 0 && useCurrent == useTotal) {
              clearInterval(timer.value);
              timer.value = 0;
              const activeValue = ref('');
              if (licensePercent.value == 100) {
                activeValue.value = 'ocr';
              }
              if (type !== activeValue.value) {
                isContinue.value = false;
                dataSource.value = {
                  content: []
                };
              } else {
                isCheckingOcr.value = false;
                showOcrTable(); //获取上传表格展示信息
              }
            } else {
              if (percentage == 100) {
                modalParams.content = '提交成功！';
              }
            }
          }
        };
        setTimeout(() => {
          const res = {
            code: '0',
            data: { current: 20, total: 100 },
            message: '进行中'
          };
          tempFun(res);
        }, 100);
        setTimeout(() => {
          const res = {
            code: '0',
            data: { current: 40, total: 100 },
            message: '进行中'
          };
          tempFun(res);
        }, 300);
        setTimeout(() => {
          const res = {
            code: '0',
            data: { current: 70, total: 100 },
            message: '进行中'
          };
          tempFun(res);
        }, 500);
        setTimeout(() => {
          const res = {
            code: '0',
            data: { current: 100, total: 100 },
            message: '成功'
          };
          tempFun(res);
        }, 600);
      } catch (e) {
        ocrProStatus.value = 'exception';
        ocrContent.value = '提交失败';
        clearInterval(timer.value);
        clearInterval(pT.value);
        timer.value = 0;
        modalParams.content = '提交失败';
      }
    };
    //ocr提交
    const batchSubmit = async () => {
      isCheckingOcr.value = true;
      ocrContent.value = '0/' + nowSelectedFileNum;
      timer.value = setInterval(async () => {
        console.log('111');
        await batchSub('ocr', licenseKey.value);
      }, 2000);
      sessionStorage.setItem('isOcrContinue', 'ocr');
      // infoVisible.value = true;
    };
    // 智传

    const submitFile = async (file: any) => {
      console.log('file', file);

      if (hasError.value) {
        hasError.value = false;
        return;
      }

      if (submitSign) {
        submitSign = false;

        licenseProStatus.value = 'normal';
        // 清空之前请求存储数据
        sessionStorage.setItem('vlLicenseData', '');
        // sessionStorage.setItem('ocrVlSuccess', '')
        sessionStorage.setItem('isOcrContinue', '');
        licenseClickCount.value = 1;
        // 转换上传数据格式
        (tempLicenseData as any).value.forEach((ele: any, index: number) => {
          licenseFormData.append('files', ele);
        });
        await batchLicense();
        // 判断上传是否成功 进行相关处理
        if (isCancelUpload) {
          setTimeout(() => {
            isCancelUpload = false;
          });
          return;
        }
        if (isLicenseSuccess.value === true) {
          ocrFileSize.value = licenseFileSize.value;
          licenseFileSize.value = '';
          // 传递相关需要保存的数据
          const licenseSession = reactive({
            vlLicenseData: tempLicenseData,
            isLicenseSuccess: true,
            licenseFileNum: licenseFileNum,
            licenseFileSize: licenseFileSize,
            licenseKey: licenseKey
          });
          sessionStorage.setItem('vlLicenseData', JSON.stringify(licenseSession));
          // licenseSubSuccess.value = true
          console.log([1, 2, '1', '2'].includes(isSelected.value));
          if (isSelected.value == 1 || isSelected.value == 2) {
            // 发票和登记证处理上传成功调用提交接口
            Modal.warning({
              title: '提示',
              content: '文字自动识别阶段，关闭窗口后可在任务中心关注后续进程及结果'
            });
            batchSubmit();
          } else {
            // 走到这步
            // 除发票和登记证的上传成功展示表格
            if (isComplete.value == '完成') {
              console.log('YYYYYYY');
              showOcrTable2(); //获取表格展示信息
            }
          }
        } else {
          modalParams.noticeType = 'error';
          modalParams.btnVisible = false;
          sessionStorage.setItem('vlLicenseData', '');
          emit('get-modal-info', {
            modalVisible: true,
            modalData: modalParams
          });
        }
      }
    };

    const isSelBtn = (param: number) => {
      activeKey.value = param + '';
      if (!licenseUploadComplete.value && licensePercent.value !== 100) {
        message.error('请等当前文件上传完成后再切换页签呦~');
      } else {
        isSelected.value = param;
        licenseFormData = new FormData(); //清空上传的参数信息
        // initCon(); //初始化上次上传结果
        fileNameShow.value =
          param == 1
            ? '二手车发票'
            : param == 2
            ? '登记证'
            : param == 3
            ? '身份证明'
            : param == 4
            ? '寄售合同'
            : param == 5
            ? '销售合同'
            : param == 6
            ? '认证报告'
            : param == 7
            ? '保修报告'
            : '其它';
        nameRules.value =
          param == 1
            ? '无要求'
            : param == 2
            ? '无要求'
            : param == 3
            ? 'SFZM_VIN'
            : param == 4
            ? 'JSHT_VIN'
            : param == 5
            ? 'XSHT_VIN'
            : param == 6
            ? 'RZBG_VIN'
            : param == 7
            ? 'BXBG_VIN'
            : 'QT_VIN';
        continueSumbit();
      }
    };
    continueSumbit(); //进入弹窗清空上传抽屉框，
    //导出提交结果
    const exportSubmitEvent = () => {
      const tableKey = (props as any).isTab + 1;
      const params = {
        url: `/rvapi/ucOrcResult/export` + '?batchId=' + sessionStorage.getItem('batchNo') + '&tableKey=' + tableKey,
        method: 'post',
        fileName:
          (props as any).isTab == 0
            ? `CPO销售${moment(new Date()).format('YYYYMMDDhhmmss')}.xlsx`
            : `置换${moment(new Date()).format('YYYYMMDDhhmmss')}.xlsx`
      };
      downloadFile(params);
    };

    const tableHeight = 200;

    const onDrop = (event: any) => {
      const files = event.dataTransfer.files;
      if (files.length > 1) {
        message.error('请选择单个文件');
        event.stopPropagation();
        event.preventDefault();
      }
    };

    onBeforeUnmount(() => {
      cancelFunction();
      if (timer.value) {
        clearInterval(timer.value);
        timer.value = 0;
      }
      if (pT.value) {
        clearInterval(pT.value);
        pT.value = 0;
      }
    });

    return {
      showVinNo,
      tableHeight,
      pagination,
      cancelFunction,
      continueSumbit,
      isCheckingOcr,
      isvisible,
      licenseUploadComplete,
      modalData,
      licenseTit,
      licenseContent,
      licenseFileSize,
      licenseConfig,
      licenseFileNum,
      isLicenseSuccess,
      licenseProStatus,
      uploadError,
      timer,
      licenseClickCount,
      orcTit,
      ocrPercent,
      ocrProStatus,
      batchSubmit,
      dataSource,
      isContinue,
      batchSub,
      closeCount,
      licensePercent,
      isResetModal,
      close,
      beforeUpload,
      batchLicense,
      isResetCount,
      licenseCustomRequest,
      submitFile,
      ocrContent,
      ocrFileSize,
      // getBatchSubmitResult,
      pageChange,
      sizeChange,
      columns,
      exportSubmitEvent,
      ocrKey,
      size,
      isSelBtn,
      nameRules,
      fileNameShow,
      isSelected,
      dataContent,
      isComplete,
      pT,
      infoVisible,
      activeKey,
      changeActiveKey,
      onDrop
    };
  }
});
