
import { defineComponent, ref, reactive, computed } from "vue";
import { FileItem } from "@/API/types";

import {
  getManager,
  cpoUploadProve,
  replacementUploadProve,
} from "@/API/delayUsedCar/dealer";
import { useStore } from "vuex";
export default defineComponent({
  components: {},
  emits: ["close-prove-visible", "get-modal-info", "init-table"],
  props: {
    isProveVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    idList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isTab: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  setup(props, context) {
    // 获取相关数据
    const store = useStore();
    const modal = (props as any).isProveVisible;
    const vinList = (props as any).idList;
    const proveVisible = ref<boolean>(false);
    const isSelect = (props as any).isTab;
    // 文件上传
    const proveUploadComplete = ref<boolean>(true);
    const proveFormData = new FormData();
    const proveFileName = ref<string>("");
    const proveFileSize = ref<string>("");
    const proveFile = ref<string>("");
    // 进度条
    const provePercent = ref<number>(0);
    const proveContent = ref<string>("0%完成");
    const proveProStatus = ref<string>("normal");
    const isProveSuccess = ref<boolean>(false);
    const proveConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          provePercent.value = complete;
          proveContent.value = complete + "%完成";
        }
      },
    };
    const proveFileData = reactive([]);
    // 弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    const manager = ref<string>();
    const managerName = ref<string>("");
    // 区域经理
    const regionManager = ref<string>("");
    const regionManagerList = ref<any[]>([]);
    const dealerCode = computed(() => {
      return (store.state.user as any).organization.entityCode;
    });
    const params = {
      dealerCode: dealerCode.value,
    };
    getManager(params).then((res) => {
      regionManagerList.value = res;
    });

    const handleSelectManager = (value: any, option: any) => {
      managerName.value = option.name;
    };

    const close = () => {
      context.emit("close-prove-visible", { isSubVisible: false });
    };

    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: "",
          content: "",
          btnVisible: false,
        });
        const isPdf = file.type === "application/pdf";
        if (!isPdf) {
          params.noticeType = "error";
          params.content = "文件格式错误，请按照PDF格式进行文件上传";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          proveProStatus.value = "exception";
          return reject(false);
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = "error";
          params.content = "文件大小必须小于5MB!";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          proveProStatus.value = "exception";
          return reject(false);
        }
        const fileLength = fileList.length;
        if (fileLength > 1) {
          params.noticeType = "error";
          params.content = "请上传单张证明";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          proveProStatus.value = "exception";
          return reject(false);
        }
        return resolve(true);
      });
    };

    const proveCustomRequest = (source: any) => {
      proveFormData.append("document", source.file);
      proveFileName.value = source.file.name;
      proveFile.value = source.file.name;
      proveFileSize.value = (source.file.size / 1024 / 1024).toFixed(2) + "MB";
      proveUploadComplete.value = false;
    };
    // 上传报错 关闭弹窗 重置相应数据
    const isResetModal = (param: { continue: boolean }) => {
      const isCloseModal = param.continue;
      if (isCloseModal) {
        if (proveProStatus.value == "exception") {
          proveUploadComplete.value = true;
          proveProStatus.value = "normal";
          provePercent.value = 0;
          proveFormData.delete("files");
        }
      }
    };

    const uploadData = reactive<any>({
      idList: [],
      regionManagerId: "",
      regionManagerName: "",
      ...proveConfig,
    });
    const submitProve = async () => {
      console.log("isSelect", isSelect, (props as any).isTab);
      if (isSelect == 0) {
        try {
          const res = await cpoUploadProve(proveFormData, uploadData);
          if (res.code !== "0") {
            isProveSuccess.value = false;
            proveProStatus.value = "exception";
            provePercent.value = 95;
            proveFileSize.value = "";
            proveContent.value = "失败";
            modalParams.content = res.message;
          } else {
            proveProStatus.value = "normal";
            modalParams.content = "上传成功！";
            isProveSuccess.value = true;
            context.emit("init-table", {});
          }
        } catch (e) {
          isProveSuccess.value = false;
          proveProStatus.value = "exception";
          provePercent.value = 95;
          proveFileSize.value = "";
          modalParams.content = "上传失败";
        }
      } else {
        try {
          const res = await replacementUploadProve(proveFormData, uploadData);
          if (res.code !== "0") {
            isProveSuccess.value = false;
            proveProStatus.value = "exception";
            provePercent.value = 95;
            proveFileSize.value = "";
            proveContent.value = "失败";
            modalParams.content = res.message;
          } else {
            proveProStatus.value = "normal";
            modalParams.content = "上传成功！";
            isProveSuccess.value = true;
          }
        } catch (e) {
          isProveSuccess.value = false;
          proveProStatus.value = "exception";
          provePercent.value = 95;
          proveFileSize.value = "";
          modalParams.content = "上传失败";
        }
      }
    };

    const submitFile = async (item: any) => {
      if (proveFile.value && manager.value) {
        uploadData.idList = vinList;
        uploadData.regionManagerId = manager.value;
        uploadData.regionManagerName = managerName.value;
        // proveFormData.append("idList", vinList);
        // proveFormData.append("regionManagerId", manager.value);
        // proveFormData.append("regionManagerName", managerName.value);
        await submitProve();
        if (isProveSuccess.value) {
          modalParams.noticeType = "success";
          modalParams.btnVisible = false;
          close();
        } else {
          modalParams.noticeType = "error";
          modalParams.btnVisible = false;
        }
      } else {
        if (!proveFile.value) {
          modalParams.noticeType = "warning";
          modalParams.btnVisible = false;
          modalParams.content = "请上传延期证明材料";
        } else {
          modalParams.noticeType = "warning";
          modalParams.btnVisible = false;
          modalParams.content = "请选择对应的区域审批人";
        }
      }
      Object.assign(modalData, modalParams);
      context.emit("get-modal-info", {
        modalVisible: true,
        modalData: modalData,
      });
    };

    return {
      modal,
      vinList,
      proveVisible,
      close,
      beforeUpload,
      proveUploadComplete,
      submitFile,
      submitProve,
      proveCustomRequest,
      provePercent,
      proveConfig,
      proveProStatus,
      proveContent,
      isProveSuccess,
      modalData,
      proveFileData,
      modalParams,
      proveFileName,
      proveFileSize,
      manager,
      regionManager,
      regionManagerList,
      proveFile,
      isResetModal,
      handleSelectManager,
    };
  },
});
